import React from 'react';
import { Row, Col } from 'antd';
import { Form, Button } from 'antd';
import { Typography } from 'antd';
import { Steps } from 'antd';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import baseUrl from '../service/baseUrl';
import { getAvarias } from "../service/api";
import Header from '../Header/Header';
import BtnFinalizar from '../BtnFinalizar/BtnFinalizar'
import { mask as masker } from "remask";
const { Step } = Steps;
const { Title } = Typography;

function getBase64Preview(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class Avarias extends React.Component {

  state = {
    uuid: '',
    cpf: '',
    posto: '',
    boletim: '',
    protocolo: '',
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileListDianteira: [],
    fileListTraseira: [],
    fileListLateralEsq: [],
    fileListLateralDir: []
  };

  mapAvariaToFile = (avaria) => {
    const time = new Date().getTime();
    return {
      "uid": avaria.uuid,
      "name": avaria.deFoto,
      "status": "done",
      "url": `${baseUrl}/avarias/foto/${avaria.uuid}?${time}`,
      "thumbUrl": `${baseUrl}/avarias/foto/${avaria.uuid}?${time}`
    };
  };

  getAvariaDianteira = (avarias) => {
    const CODIGO_FOTO_AVARIA_DIANTEIRA = 1;
    return avarias.filter(
      avaria => avaria.nuFoto === CODIGO_FOTO_AVARIA_DIANTEIRA
    ).map(this.mapAvariaToFile);
  };

  getAvariaTraseira = (avarias) => {
    const CODIGO_FOTO_AVARIA_TRASEIRA = 2;
    return avarias.filter(
      avaria => avaria.nuFoto === CODIGO_FOTO_AVARIA_TRASEIRA
    ).map(this.mapAvariaToFile);
  };

  getAvariaLateralEsq = (avarias) => {
    const CODIGO_FOTO_AVARIA_LATERAL_ESQ = 3;
    return avarias.filter(
      avaria => avaria.nuFoto === CODIGO_FOTO_AVARIA_LATERAL_ESQ
    ).map(this.mapAvariaToFile);
  };

  getAvariaLateralDir = (avarias) => {
    const CODIGO_FOTO_AVARIA_LATERAL_DIR = 4;
    return avarias.filter(
      avaria => avaria.nuFoto === CODIGO_FOTO_AVARIA_LATERAL_DIR
    ).map(this.mapAvariaToFile);
  };

  getDadosHeader = (avarias) => {
    if (!avarias || avarias.length === 0) return {};

    return avarias[0];
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { match: { params } } = this.props;

    getAvarias(params.uuid).then((avarias) => {

      const fileListDianteria = this.getAvariaDianteira(avarias);

      const fileListTraseira = this.getAvariaTraseira(avarias);

      const fileListLateralEsq = this.getAvariaLateralEsq(avarias);

      const fileListLateralDir = this.getAvariaLateralDir(avarias);

      const header = this.getDadosHeader(avarias);

      const { nuCpfCondutor, nuProtocoloRoat, cdPostorodov, nuAno, nuBoletim } = header;

      this.setState({
        disabled: true,
        uuid: params.uuid,
        cpf: masker(nuCpfCondutor, '999.999.999-99'),
        protocolo: nuProtocoloRoat,
        posto: cdPostorodov,
        ano: nuAno,
        boletim: nuBoletim,
        fileListDianteira: fileListDianteria,
        fileListTraseira: fileListTraseira,
        fileListLateralEsq: fileListLateralEsq,
        fileListLateralDir: fileListLateralDir
      });

    });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Preview(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      file.status = 'error';
      message.error('A imagem deve estar no formato JPEG/PNG.');
    }

    return isJpgOrPng;
  };

  handleFileStatusError = (fileList) => {
    const file = fileList && fileList.length > 0 ? fileList[0] : null;

    if (file && file.status === 'error') file.thumbUrl = null;
  };

  handleChangeDianteira = ({ fileList }) => {
    this.handleFileStatusError(fileList);

    this.setState({ fileListDianteira: fileList })
  };

  handleChangeTraseira = ({ fileList }) => {
    this.handleFileStatusError(fileList);

    this.setState({ fileListTraseira: fileList })
  };

  handleChangeLateralEsq = ({ fileList }) => {
    this.handleFileStatusError(fileList);

    this.setState({ fileListLateralEsq: fileList })
  };

  handleChangeLateralDir = ({ fileList }) => { 
    this.handleFileStatusError(fileList);
    
    this.setState({ fileListLateralDir: fileList })
  };

  render() {
    const { previewVisible, previewImage, previewTitle } = this.state;
    const { fileListDianteira, fileListTraseira, fileListLateralEsq, fileListLateralDir } = this.state;
    const { uuid, protocolo, cpf, boletim, posto, ano} = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <div className="background-login" style={{ height: '100%' }}>
        <div className="absolute-wrapper">
          <div className="colored-backgrounds">
            <div></div>
            <div></div>
          </div>
        </div>
        <main>
          <Header
            protocolo={protocolo}
            cpf={cpf}
            boletim={boletim}
            posto={posto}
            ano={ano}
          />
          <div className="content-declaracao">
            <div className="nest">
              <div className="nestfs">
                <Row className="col-direct" style={{ width: '100%' }}>
                  <Col lg={18} className="col-forms">
                    <Title className="titulo-tela" level={2}>Fotos das Avarias</Title>
                    <form className="form-habilitacao">
                      <Row style={{ marginTop: '2rem' }}>
                        <React.Fragment>
                          <Col md={12}>
                            <Title level={4}>Dianteira</Title>
                            <Upload
                              action={`${baseUrl}/avarias/${uuid}/dianteira`}
                              listType="picture-card"
                              fileList={fileListDianteira}
                              onPreview={this.handlePreview}
                              onChange={this.handleChangeDianteira}
                              beforeUpload={this.beforeUpload}
                            >
                              {fileListDianteira.length >= 1 ? null : uploadButton}
                            </Upload>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Title level={4}>Traseira</Title>
                            <Upload
                              action={`${baseUrl}/avarias/${uuid}/traseira`}
                              listType="picture-card"
                              fileList={fileListTraseira}
                              onPreview={this.handlePreview}
                              onChange={this.handleChangeTraseira}
                              beforeUpload={this.beforeUpload}
                            >
                              {fileListTraseira.length >= 1 ? null : uploadButton}
                            </Upload>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row style={{ marginTop: '2rem' , marginBottom: '2rem' }}>
                        <React.Fragment>
                          <Col md={12}>
                            <Title level={4}>Lateral Esquerda</Title>
                            <Upload
                              action={`${baseUrl}/avarias/${uuid}/lateralesq`}
                              listType="picture-card"
                              fileList={fileListLateralEsq}
                              onPreview={this.handlePreview}
                              onChange={this.handleChangeLateralEsq}
                              beforeUpload={this.beforeUpload}
                            >
                              {fileListLateralEsq.length >= 1 ? null : uploadButton}
                            </Upload>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Title level={4}>Lateral Direita</Title>
                            <Upload
                              action={`${baseUrl}/avarias/${uuid}/lateraldir`}
                              listType="picture-card"
                              fileList={fileListLateralDir}
                              onPreview={this.handlePreview}
                              onChange={this.handleChangeLateralDir}
                              beforeUpload={this.beforeUpload}
                            >
                              {fileListLateralDir.length >= 1 ? null : uploadButton}
                            </Upload>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <div className="dot"></div>
                        <div className="dot2"></div>
                        <div className="dot3"></div>
                        <div className="line"></div>
                        <div className="cross"></div>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                      <Row>
                        <Col md={16}></Col>
                        <Col md={8} id="botoes-navegacao">
                          <Form.Item>
                              <BtnFinalizar uuid={ uuid } fileListDianteira = { fileListDianteira }
                              fileListTraseira = { fileListTraseira } fileListLateralDir = { fileListLateralDir } fileListLateralEsq = { fileListLateralEsq } />
                          </Form.Item>
                          <Form.Item>
                            <Button size="large" block><Link to={`/habilitacao/${this.state.uuid}`}>Anterior</Link></Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                  <Col lg={6}>
                    <Steps direction="vertical" percent={99} current={5} style={{ width: 'auto' }}>
                      <Step title="Dados do Veículo" />
                      <Step title="Dados do Proprietário" />
                      <Step title="Dados do Condutor" />
                      <Step title="Declaração do Condutor" />
                      <Step title="Dados da Habilitação" />
                      <Step title="Fotos das Avarias" />
                    </Steps>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Avarias;