import axios from 'axios';
import baseUrl from './baseUrl';

export const getCor = () => {
    return axios.get(baseUrl + 'veiculos/cores')
    .then(( resp ) => {
       return resp.data.sort((a,b) => a.deCor < b.deCor ? -1 : a.deCor > b.deCor ? 1 : 0);
    });
}

export const getTipo =  () => {
    return axios.get(baseUrl + 'veiculos/tipos')
    .then(( resp ) => {
        return resp.data.sort((a, b) => a.deTipoveiculo < b.deTipoveiculo ? -1 : a.deTipoveiculo > b.deTipoveiculo ? 1 : 0);
    });    
}

export const getCategoria =  () => {
    return axios.get(baseUrl + 'veiculos/categorias')
    .then(( resp) => {
        return resp.data.sort((a, b) => a.deCategoria < b.deCategoria ? -1 : a.deCategoria > b.deCategoria ? 1 : 0);
    });
}

export const getEspecie =  () => {
    return axios.get(baseUrl + 'veiculos/especies')
    .then((resp) => {
        return resp.data.sort((a, b) => a.deEspecie < b.deEspecie ? -1 : a.deEspecie > b.deEspecie ? 1 : 0);
    })
}

export const getMotivoViagem =  () => {
    return axios.get(baseUrl + 'motivosviagem')
    .then((resp) => {
        return resp.data.sort((a, b) => a.deMotivoviagem < b.deMotivoviagem ? -1 : a.deMotivoviagem > b.deMotivoviagem ? 1 : 0);
    })
    
}

export const getUnidadeFederal =  () => {
    return axios.get(baseUrl + 'enderecos/ufs')
    .then((resp) => {
        return resp.data.sort((a, b) => a.sgUnidadeFederal < b.sgUnidadeFederal ? -1 : a.sgUnidadeFederal > b.sgUnidadeFederal ? 1 : 0);          
    })
}

export const getMunicipiosPorUf =  (uf) => {    
    return axios.get(baseUrl + 'enderecos/municipios/uf/' + uf)
    .then((resp) => {
        return resp.data.sort((a, b) => a.nmMunicipio < b.nmMunicipio ? -1 : a.nmMunicipio > b.nmMunicipio ? 1 : 0);
    })
}

export const getListaDeMunicipios =  () => {    
    return axios.get(baseUrl + 'enderecos/municipios/')
    .then((resp) => {
        return resp.data.sort((a, b) => a.nmMunicipio < b.nmMunicipio ? -1 : a.nmMunicipio > b.nmMunicipio ? 1 : 0);
    })
}

export const getDadosDoVeiculo = (uuid) => {     
    return axios.get(`${baseUrl}veiculos/${uuid}`)
    .then((resp) => {
      return resp.data;
    });
}

export const getDadosDoVeiculoSC = (uuid) => {     
    return axios.get(`${baseUrl}veiculos/${uuid}/placa-sc`)
    .then((resp) => {
      return resp.data;
    });
}

export const getPaises = () => {     
    return axios.get(baseUrl + 'enderecos/paises/')
    .then((resp) => {
      return resp.data.sort((a, b) => a.nmPais < b.nmPais ? -1 : a.nmPais > b.nmPais ? 1 : 0);
    });
}

export const getEstadosPorPais = (pais) => {     
    return axios.get(`${baseUrl}enderecos/paises/ufs/${pais}`)
    .then((resp) => {
      return resp.data;
    });
}

export const getDadosDoCondutor = (uuid) => {     
    return axios.get(`${baseUrl}condutores/${uuid}`)
    .then((resp) => {
      return resp.data;
    });
}

export const getEstadoCivil = () => {
    return axios.get(baseUrl + 'condutores/estadocivil/')
    .then((resp) => {
        return resp.data.sort((a, b) => a.nmdeEstadoCivil < b.nmdeEstadoCivil ? -1 : a.nmdeEstadoCivil > b.nmdeEstadoCivil ? 1 : 0);
    });    
}

export const getCategoriaHabilitacao =  () => {
    return axios.get(baseUrl + 'habilitacoes/categorias/')
    .then(( resp) => {
        return resp.data.sort((a, b) => a.deCategoria < b.deCategoria ? -1 : a.deCategoria > b.deCategoria ? 1 : 0);
    });
}

export const getAvarias = (uuid) => {
    return axios.get(`${baseUrl}avarias/${uuid}`)
    .then((resp) => {
        return resp.data;
    });
}

export const getEnderecoProprietario = (uuid) => {
    return axios.get(`${baseUrl}proprietarios/enderecos/${uuid}`)
    .then((resp) => {
        return resp.data;
    });
}

export const finalizaVeiculo = (uuid) => {     
    return axios.put(`${baseUrl}/finalizar/${uuid}`)
    .then((resp) => {
    });
}

export const finalizarPreenchimentoRoat = (uuid) => {
    return axios.put(`${baseUrl}/abertura/${uuid}/`)
    .then((resp) => {
    });
}

export const getParametro = (parametro) => {
    return axios.get(`${baseUrl}parametros/${parametro}`)
}
