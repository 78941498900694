import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './Home/index.js'
import Habilitacao from './Habilitacao/index.js';
import Veiculo from './Veiculo/index.js';
import Proprietario from './Proprietario/index.js';
import Condutor from './Condutor/index.js';
import Declaracao from './Declaracao/index.js';
import Avarias from './Avarias/index.js';


import './App.css';

function App() {
  return (
    <Router>
      <div className="App">       

        <Switch>

          <Route exact={true} path="/">
            <Home />
          </Route>
          <Route path="/veiculo/:uuid" component={Veiculo} />
          <Route path="/proprietario/:uuid" component={Proprietario} />
          <Route path="/condutor/:uuid" component={Condutor} />
          <Route path="/declaracao/:uuid" component={Declaracao} />
          <Route path="/habilitacao/:uuid" component={Habilitacao} />
          <Route path="/avarias/:uuid" component={Avarias} />              
        </Switch>
      </div>
    </Router>
  );
}

export default App;