import { getMunicipiosPorUf } from "../service/api";
import cep from 'cep-promise';

export const buscadorDeCep = (ceps, callback, retornoSpinCep) =>{
  const cepNaoEncontrado = "Cep não encontrado nas bases Correios, Via CEP";
    return cep(ceps)
        .catch(function (){
          retornoSpinCep(false, cepNaoEncontrado);
        })
        .then(function (response){
            if(response != null){
            getMunicipiosPorUf(response.state).then((municipios) => { 
              
                var cdMuni = municipios.find(municipio => 
                  removerAcentos(municipio.nmMunicipio) === removerAcentos(response.city)
                ).cdMunicipio;
                const data = {
                    ...response,
                    cityId: cdMuni
                };
                callback(data);
            });
          }
        });
}

export const removerAcentos = ( newStringComAcento ) => {
  var string = newStringComAcento;
    var mapaAcentosHex 	= {
        a : /[\xE0-\xE6]/g,
        e : /[\xE8-\xEB]/g,
        i : /[\xEC-\xEF]/g,
        o : /[\xF2-\xF6]/g,
        u : /[\xF9-\xFC]/g,
        c : /\xE7/g,
        n : /\xF1/g
    };

    for ( var letra in mapaAcentosHex ) {
        var expressaoRegular = mapaAcentosHex[letra];
        string = string.replace( expressaoRegular, letra );
    }

    return string;
}


