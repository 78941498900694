import React from 'react';
import { Row, Col} from 'antd';
import { Form, Input, Button, DatePicker, Select } from 'antd';
import { Typography } from 'antd';
import { Steps, Spin } from 'antd';
import { Link } from "react-router-dom";
import moment from 'moment';
import 'antd/dist/antd.css';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import axios from 'axios'
import baseUrl from '../service/baseUrl';
import Header from '../Header/Header';
import { mask as masker} from "remask";
import { getPaises, getUnidadeFederal, getCategoriaHabilitacao, getEstadosPorPais } from "../service/api";
const { Step } = Steps;
const { Title } = Typography;

class Habilitacao extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      cpf: '',
      protocolo: '',
      posto: '',
      ano: '',
      boletim: '',
      nuVeiculo: '',
      nuCPFCondutor: '',
			nuCnh: '',
			cdCategHabilit: '',
			deCateghabilit: '',
			dtExpedicao: '',
			dtValidade: '',
			cdPaisCnh: '',
			nmPais: '',
			sgUfCnh: '',
      ufs: [],
      uf: '',
      tipo: '',
      paises: [],
      uuid: '',
      categorias: [],
      disable: false,
      loading: false,
    }
    this.setPais = this.setPais.bind(this);
    this.setUf = this.setUf.bind(this);
    this.setCategoria = this.setCategoria.bind(this);
    this.setDataExpedicao = this.setDataExpedicao.bind(this);
    this.setDataValidade = this.setDataValidade.bind(this);
    this.setNuRegistro = this.setNuRegistro.bind(this);
  }
  setPais(pais) {
    const BRASIL = 1;
    this.setState({ cdPaisCnh: pais });
    if (pais !== BRASIL) {
      getEstadosPorPais(pais).then((uf) => { this.setState({ sgUfCnh: uf[0].sgUnidadeFederal, disabled: true,}); });
    } else {
      this.setState({ disabled: false });
    }
  };

  setDataExpedicao(dataExped) { this.setState({ dtExpedicao: dataExped }) };
  setDataValidade(dataValid) { this.setState({ dtValidade: dataValid }) };
 
  setUf(unidadeFederal) {
    this.setState({ sgUfCnh: unidadeFederal })
  };

  setNuRegistro(numero){
    this.setState({ nuCnh : numero.target.value.replace(/[^\d\s-/]/g, "")})
  };

  setCategoria(categoria){
    this.setState({cdCategHabilit: categoria})
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    var othis = this;
    const { match: { params } } = this.props;
    axios
      .get(baseUrl + 'habilitacoes/' + params.uuid)
      .then(function (response) {
        var cpf = '';
        if(null !== response.data.nuCpfCondutor || '' !== response.data.nuCpfCondutor){
          cpf = masker(response.data.nuCpfCondutor, '999.999.999-99');
        }

        othis.setState({
          uuid: params.uuid,
          posto: response.data.cdPostorodov,
          ano: response.data.nuAno,
          boletim: response.data.nuBoletim,
          nuVeiculo: response.data.nuVeiculo,
          cpf: cpf,
          protocolo: response.data.nuProtocoloRoat,
          nuCnh: response.data.nucnh,
          cdCategHabilit : response.data.cdCategHabilit,
          deCategHabilit : response.data.deCategHabilit,
          dtExpedicao: response.data.dtexpedicao,
          dtValidade: response.data.dtvalidade,
          cdPaisCnh : response.data.cdpaiscnh,
          nmPais: response.data.NMPAIS,
          sgUfCnh: response.data.sgUfcnh,
        });

        getPaises().then(function (pais) {
          othis.setState({ paises: pais })
        });

        getUnidadeFederal().then(function (uf) {
          othis.setState({
            ufs: uf
          })
        });

        getCategoriaHabilitacao().then(function (categorias){
          othis.setState({
            categorias: categorias
          })
        })
      });
  }
  salvarDadosHabilitacao = () => {
    this.setState({ loading: true });
    axios.put(baseUrl + "habilitacoes/",
      {
      "posto": this.state.posto,
      "ano": this.state.ano,
      "boletim": this.state.boletim,
      "veiculo": this.state.nuVeiculo,
      "nuCnh": this.state.nuCnh,
	    "cdCategHabilit": this.state.cdCategHabilit,
      "deCategHabilit": this.state.deCategHabilit,
	    "dtExpedicao": this.state.dtExpedicao,
      "dtValidade": this.state.dtValidade,
	    "cdPaisCnh": this.state.cdPaisCnh,
	    "sgUfCnh": this.state.sgUfCnh,
      }
    ).then(resp => {
      this.setState({ loading: false });
    })
    }
  render() {
    const { ufs } = this.state;
    const { paises } = this.state;
    const { categorias } = this.state;
    const dateFormat = 'DD/MM/YYYY';
    const dataExpedicao = this.state.dtExpedicao ? new Date(this.state.dtExpedicao).toLocaleDateString() : null;
    const dataValidade = this.state.dtValidade ? new Date(this.state.dtValidade).toLocaleDateString() : null;
    return (
      <div className="background-login">
        <div className="loading" hidden={!this.state.loading}>    
              <Spin className="spinCentro" spinning={this.state.loading} delay={500} size="large"/>
         </div>
        <div className="absolute-wrapper">
          <div className="colored-backgrounds">
            <div></div>
            <div></div>
          </div>
        </div>
        <main>
          <Header protocolo={this.state.protocolo}
            cpf={this.state.cpf}
            boletim={this.state.boletim}
            posto={this.state.posto}
            ano={this.state.ano}
            onExit={this.salvarDadosHabilitacao.bind(this)}/>
          <div className="content-declaracao">
            <div className="nest">
              <div className="nestfs">
                <Row className="col-direct" style={{ width: '100%' }}>
                  <Col className="col-forms" lg={18}>
                    <Title className="titulo-tela" level={2}>Dados da Habilitação</Title>
                    <form className="form-habilitacao">
                      <Row>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label="Nº de Registro" className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder="Nº de Registro" size="large" value={this.state.nuCnh} onChange={this.setNuRegistro} maxLength="15"/>
                              <label>Nº de Registro</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label="Data 1ª CNH" className="label-float">
                            <DatePicker allowClear size="large" locale={locale} value={dataExpedicao ? moment(dataExpedicao, dateFormat, 'L') : null } format={dateFormat} onChange={this.setDataExpedicao} />
                              <label className="selectlabel">Data 1ª CNH</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                          <Form.Item label="País" className="label-float">
                              <Select placeholder="Escolha o País" size="large" value={this.state.cdPaisCnh} onChange={this.setPais} defaultValue={this.state.nmPais} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {paises.map((pais) => (<Select.Option key={pais.cdPais} value={pais.cdPais} > {pais.nmPais}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">Escolha o País</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label="Categoria" className="label-float">
                              <Select placeholder="Escolha a Categoria" size="large" value={this.state.cdCategHabilit} onChange={this.setCategoria} defaultValue={this.state.deCategHabilit} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {categorias.map((categoria) => (<Select.Option key={categoria} value={categoria.cdCategHabilit} > {categoria.deCategHabilit}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">Escolha a Categoria</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                          <Form.Item label="Validade" className="label-float">
                          <DatePicker allowClear size="large" locale={locale} value={dataValidade ? moment(dataValidade, dateFormat, 'L') : null} format={dateFormat} onChange={this.setDataValidade} />
                              <label className="selectlabel">Validade</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                          <Form.Item label="UF" className="label-float">
                              <Select disabled={this.state.disabled} placeholder="Selecione o Estado" size="large" value={this.state.sgUfCnh} onChange={this.setUf} defaultValue={this.state.sgUfCnh} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {ufs.map((uf) => (<Select.Option key={uf.sgUnidadeFederal} value={uf.sgUnidadeFederal} >{uf.sgUnidadeFederal}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">UF</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <Col md={24} id="botoes-navegacao">
                          <Form.Item>
                            <Button type="primary" size="large" block onClick={this.salvarDadosHabilitacao} 
                               disabled={!this.state.nuCnh || !this.state.cdCategHabilit || !this.state.dtValidade 
                               || !this.state.dtExpedicao || !this.state.cdPaisCnh || !this.state.sgUfCnh }>
                               <Link to={`/avarias/${this.state.uuid}`}>Proximo</Link>
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button size="large" block onClick={this.salvarDadosHabilitacao}><Link to={`/declaracao/${this.state.uuid}`}>Anterior</Link></Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </form>
                  </Col>

                  <Col lg={6}>
                    <Steps direction="vertical" percent={80} current={4} style={{ width: 'auto' }}>
                      <Step title="Dados do Veículo" />
                      <Step title="Dados do Proprietário" />
                      <Step title="Dados do Condutor" />
                      <Step title="Declaração do Condutor" />
                      <Step title="Dados da Habilitação" />
                      <Step title="Fotos das Avarias" />
                    </Steps>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Habilitacao;