import React from 'react';
import { Row, Col } from 'antd';
import { Form, Input, Button, DatePicker, Select } from 'antd';
import { Typography } from 'antd';
import { Steps, message, Spin } from 'antd';
import { Link } from "react-router-dom";
import { getUnidadeFederal, getMunicipiosPorUf, getPaises, getEstadoCivil, getDadosDoCondutor, getEstadosPorPais, getListaDeMunicipios, getEnderecoProprietario } from "../service/api";
import { mask as masker, unMask } from "remask";
import moment from 'moment';
import 'antd/dist/antd.css';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import axios from 'axios';
import baseUrl from "../service/baseUrl";
import Header from '../Header/Header';
import { buscadorDeCep } from '../service/cep';
import { SearchOutlined } from '@ant-design/icons';
const { Step } = Steps;
const { Title } = Typography;

class Condutor extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {

      municipios: [], ufs: [], naturalidades: [], paises: [], estadosCivis: [], disabled: '', desabilite: '', ufsRgs: [],

      posto: '', ano: '', boletim: '', veiculo: '', cpf: '', protocolo: '', rg: '', ufRg: '', nome: '', nacionalidade: '', cdNaturalidade: '', flSexo: '', dataNasc: '', cdEstadoCivil: '', email: '',
      rua: '', complemento: '', ufEnd: '', deMunicipioEnd: '', telefone: '', numero: '', bairro: '', uuid: '', deEstadoCivil: '',
      cdMunicipioEnd: '', pais: '', deNaturalidade: '', cep: '', BRASIL: 1, naturalidade: '', loading: false,
    }

    this.setCep = this.setCep.bind(this);
    this.setRg = this.setRg.bind(this);
    this.setPais = this.setPais.bind(this);
    this.setUfEnd = this.setUfEnd.bind(this);
    this.setCpf = this.setCpf.bind(this);
    this.setRua = this.setRua.bind(this);
    this.setSexo = this.setSexo.bind(this);
    this.setNome = this.setNome.bind(this);
    this.setUfRg = this.setUfRg.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setBairro = this.setBairro.bind(this);
    this.setNumero = this.setNumero.bind(this);
    this.setDataNasc = this.setDataNasc.bind(this);
    this.setTelefone = this.setTelefone.bind(this);
    this.setMunicipioEnd = this.setMunicipioEnd.bind(this);
    this.setComplemento = this.setComplemento.bind(this);
    this.setEstadoCivil = this.setEstadoCivil.bind(this);
    this.setNaturalidade = this.setNaturalidade.bind(this);
    this.setNacionalidade = this.setNacionalidade.bind(this);
    this.buscaCep = this.buscaCep.bind(this);
    this.copiaEnderecoProprietario = this.copiaEnderecoProprietario.bind(this);
  }
  setCep(ceps) {
    this.setState({ cep: masker(ceps.target.value, '99.999-999') });

  };
  setRg(rg) { this.setState({ rg: rg.target.value }) };
  setCpf(cpf) { this.setState({ cpf: masker(cpf.target.value, '999.999.999-99') }); };

  setRua(rua) { this.setState({ rua: rua.target.value }) };
  setSexo(sexo) { this.setState({ flSexo: sexo }) };
  setNome(nome) { this.setState({ nome: nome.target.value }) };
  setEmail(email) { this.setState({ email: email.target.value }) };
  setNumero(numero) { this.setState({ numero: numero.target.value }) };
  setBairro(bairro) { this.setState({ bairro: bairro.target.value }) };
  setMunicipioEnd(cdMunicipio) { this.setState({ cdMunicipioEnd: cdMunicipio, deMunicipioEnd: cdMunicipio }); };
  setDataNasc(dataNasc) { this.setState({ dataNasc: dataNasc }) };
  setEstadoCivil(estadoCivil) { this.setState({ cdEstadoCivil: estadoCivil, deEstadoCivil: estadoCivil }); };
  setTelefone(telefone) {
    this.setState({ telefone: masker(telefone.target.value, '(99)999999999') });
  };
  setNaturalidade(naturalidade) { this.setState({ cdNaturalidade: naturalidade, deNaturalidade: naturalidade, desabilite: false, naturalidade: naturalidade }) };
  setNacionalidade(nacionalidade) {
    const BRASILEIRO = 1;
    this.setState({ nacionalidade: nacionalidade });
    if (nacionalidade !== BRASILEIRO) {
      this.setState({ cdNaturalidade: '', deNaturalidade: '', desabilite: true, naturalidade: true });
    } else {
      this.setState({ desabilite: false, naturalidade: false });
    }
  }
  setComplemento(complemento) { this.setState({ complemento: complemento.target.value }); };
  setPais(pais) {
    this.setState({ pais: pais, });
    if (pais !== this.state.BRASIL) {
      getEstadosPorPais(pais).then((uf) => { this.setState({ ufEnd: uf[0].sgUnidadeFederal, disabled: true, cdMunicipioEnd: '', deMunicipioEnd: '', cep: '' }); });
    } else {      
      this.setState({ disabled: false, ufEnd: ''});
    }
  };

  setUfEnd(ufEndCond) {
    this.setState({ ufEnd: ufEndCond })
    getMunicipiosPorUf(ufEndCond).then((municipio) => { this.setState({ municipios: municipio, cdMunicipioEnd: municipio[0].cdMunicipio, deMunicipioEnd: '' }); });
  };

  setUfRg(unidadeFederalRg) { this.setState({ ufRg: unidadeFederalRg }) };
 
  cepCallback = (data) => {
    this.setState({
      rua: data.street,
      bairro: data.neighborhood,
      deMunicipioEnd: data.city,
      cdMunicipioEnd: data.cityId,
      ufEnd: data.state,
      loading: false
    });
  
  };
  retornoSpinCep = (spin, msg) => {
    this.setState({ loading: spin });
    message.error(msg);
  }

  buscaCep (){
    this.setState({ loading: true });
    var ceps = unMask(this.state.cep).toString();
    if(!ceps || ceps.length < 8){
      this.setState({ loading: false });
      message.error("Favor preencher um cep válido.");
      return;
    }
    if(ceps.length === 8){
      buscadorDeCep(ceps, this.cepCallback, this.retornoSpinCep);
    } else {  
      let mensagem = 'Cep Invalido.';
      message.error(mensagem, [5], null);
    }
  }
      
  componentDidMount() {
    window.scrollTo(0, 0);
    getUnidadeFederal().then((uf) => { this.setState({ ufs: uf }) });
    getPaises().then((pais) => { this.setState({ paises: pais }) });
    getEstadoCivil().then((estadocivil) => { this.setState({ estadosCivis: estadocivil }) })
    getUnidadeFederal().then((ufrgs) => { this.setState({ ufsRgs: ufrgs, disabled: false }) });
    getListaDeMunicipios().then((naturalidade) => { this.setState({ naturalidades: naturalidade }) });

    const { match: { params } } = this.props;
    getDadosDoCondutor(params.uuid).then((dados) => {
      var cpf = '';
      var cep = '';
      if (dados.flSexo === "F") {
        this.setState({ sexo: "Feminino" });
      } else {
        this.setState({ sexo: "Masculino" });
      }
      if (dados.cdMunicCondutor !== '') {
        getMunicipiosPorUf(dados.ufendCond).then((municipio) => { this.setState({ municipios: municipio }); });
      }
      if(null !== dados.nuCpfCondutor){
        cpf = masker(dados.nuCpfCondutor, '999.999.999-99')
      }
      if(null !== dados.nuCepCondutor){
        cep = masker(dados.nuCepCondutor, '99.999-999')
      }
      if(this.state.BRASIL !== dados.cdPaisEndCondutor){
        this.setState({ disabled: true });
      }      
      
      this.setState({
        uuid: params.uuid,
        posto: dados.cdPostorodov,
        veiculo: dados.nuVeiculo,
        boletim: dados.nuBoletim,
        ano: dados.nuAno,
        maskCpf: cpf,
        protocolo: dados.nuProtocoloRoat,
        nome: dados.nmCondutor,
        flSexo: dados.flSexo,
        deNaturalidade: dados.deNaturalidade,
        cdNaturalidade: dados.cdNaturalidade,
        dataNasc: dados.dtNascimento,
        email: dados.deEmailCondutor,
        numero: dados.nuNumeroCond,
        nacionalidade: dados.cdNacCond,
        rua: dados.deEnderecoCond,
        complemento: dados.deComplementoCond,
        deEstadoCivil: dados.cdEstadocivil,
        cdEstadoCivil: dados.cdEstadocivil,
        rg: dados.nuRegistroGeral,
        ufRg: dados.sgUnidadeFederal,
        deMunicipioEnd: dados.deMunicEndCond,
        cdMunicipioEnd: dados.cdMunicCondutor,       
        bairro: dados.nmBairroCond,
        telefone: dados.nuTelefoneCond,
        ufEnd: dados.sgUfEndCondutor,
        pais: dados.cdPaisEndCondutor !== null && dados.cdPaisEndCondutor !== '' ? dados.cdPaisEndCondutor : 1,
        cep: cep,
        disabled: false,
        naturalidade: dados.cdNacCond !== '' || dados.cdNaturalidade !== '',
      }) 
    });    
  }

  salvarDadosCondutor = () => {
    this.setState({ loading: true });
    axios.put(baseUrl + "condutores/",
      {
        "posto": this.state.posto,
        "ano": this.state.ano,
        "boletim": this.state.boletim,
        "veiculo": this.state.veiculo,
        "rg": this.state.rg,
        "cdMunicCondutor": this.state.cdMunicipioEnd,
        "nmCondutor": this.state.nome,
        "dtNascimento": this.state.dataNasc,
        "flSexo": this.state.flSexo,
        "telefone": this.state.telefone,
        "sgUnidadefederal": this.state.ufRg,
        "nacionalidade": this.state.nacionalidade,
        "naturalidade": this.state.cdNaturalidade,
        "estadocivil": this.state.cdEstadoCivil,
        "endereco": this.state.rua,
        "nuCepcondutor": unMask(this.state.cep),
        "numero": this.state.numero,
        "complemento": this.state.complemento,
        "bairro": this.state.bairro,
        "email": this.state.email,
        "sgUfEndCondutor": this.state.ufEnd,
        "cdPaisEndCondutor": this.state.pais,
        "pais": this.state.pais,
      }
    ).then(resp => {
      this.setState({ loading: false });
    })
  }

  copiaEnderecoProprietario = () => {
    const { match: { params } } = this.props;
    getEnderecoProprietario(params.uuid).then((dados) => {
      this.setState({   
        numero: dados.nuNumeroProp,       
        rua: dados.deEndereco,
        complemento: dados.deComplementoProp,      
        deMunicipioEnd: dados.municPropriet,
        cdMunicipioEnd: dados.cdMunicPropriet,       
        bairro: dados.nmBairroPropriet,
        ufEnd: dados.sgUnidFederalPropriet,
        cep: masker(dados.nuCepPropriet, '99.999-999'),
        telefone: dados.nuTelefone
      });
   }); 
  }
  render() {
    const { ufs } = this.state;
    const { ufsRgs } = this.state;
    const { municipios } = this.state;
    const { naturalidades } = this.state;
    const { paises } = this.state;
    const { estadosCivis } = this.state;
    const dateFormat = 'DD/MM/YYYY';
    const dtNascimento = this.state.dataNasc ? new Date(this.state.dataNasc).toLocaleDateString() : null;    
    return (
      <div className="background-login" style={{ height: '100%' }}>
        <div className="loading" hidden={!this.state.loading}>    
              <Spin className="spinCentro" spinning={this.state.loading} delay={500} size="large"/>
         </div>
        <div className="absolute-wrapper">
          <div className="colored-backgrounds">
            <div></div>
            <div></div>
          </div>
        </div>
        <main>
          <Header protocolo={this.state.protocolo}
            cpf={this.state.maskCpf}
            boletim={this.state.boletim}
            posto={this.state.posto}
            ano={this.state.ano}
            onExit={this.salvarDadosCondutor.bind(this)}/>
          <div className="content-declaracao">
            <div className="nest">
              <div className="nestfs">
                <Row className="col-direct" style={{ width: '100%' }}>
                  <Col className="col-forms" lg={18}>
                    <Title className="titulo-tela" level={2}>Dados do Condutor</Title>
                    <form className="form-habilitacao">
                      <Row>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label="CPF" className="label-float" >
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder="CPF" disabled size="large" maxLength="14" value={this.state.maskCpf} />
                              <label>CPF</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label="RG" className="label-float">
                              <Input placeholder="RG" size="large" value={this.state.rg} onChange={this.setRg} maxLength="15"/>
                              <label>RG</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={8}>
                            <Form.Item label="UF do RG" className="label-float">
                              <Select placeholder="UF do RG" size="large" value={this.state.ufRg} onChange={this.setUfRg} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {ufsRgs.map((ufrg) => (<Select.Option key={ufrg.idUnidadeFederal} value={ufrg.sgUnidadeFederal} >{ufrg.sgUnidadeFederal}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">UF do RG</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={24}>
                            <Form.Item label="Nome" className="label-float">
                              <Input placeholder="Nome" size="large" value={this.state.nome} onChange={this.setNome} maxLength="150"/>
                              <label>Nome</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Nacionalidade" className="label-float">
                              <Select placeholder="Selecione Nacionalidade" size="large" value={this.state.nacionalidade} onChange={this.setNacionalidade} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {paises.map((pais) => (<Select.Option key={pais.cdPais} value={pais.cdPais} >{pais.deNacionalidade}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">Nacionalidade</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Naturalidade" className="label-float">
                              <Select disabled={this.state.desabilite} placeholder="Naturalidade" size="large" value={this.state.deNaturalidade} onChange={this.setNaturalidade} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {naturalidades.map((natural) => (<Select.Option key={natural.cdMunicipio} value={natural.cdMunicipio}>{natural.nmMunicipio}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">Naturalidade</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Sexo" className="label-float">
                              <Select placeholder="Sexo" size="large" value={this.state.flSexo} onChange={this.setSexo}>
                                <Select.Option value="M">Masculino</Select.Option>
                                <Select.Option value="F">Feminino</Select.Option>
                              </Select>
                              <label className="selectlabel">Sexo</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Data de Nascimento" className="label-float">
                            <DatePicker size="large" locale={locale} value={dtNascimento ? moment(dtNascimento, dateFormat, 'L') : null} format={dateFormat} onChange={this.setDataNasc} />
                              <label className="selectlabel">Data de Nascimento</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Estado Civil" className="label-float">
                              <Select placeholder="Estado Civil" size="large" value={this.state.deEstadoCivil} onChange={this.setEstadoCivil} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {estadosCivis.map((estadocivil) => (<Select.Option key={estadocivil.cdEstadoCivil} value={estadocivil.cdEstadoCivil}>{estadocivil.deEstadoCivil}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">Estado Civil</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="E-mail" className="label-float">
                              <Input placeholder="E-mail" size="large" value={this.state.email} onChange={this.setEmail} maxLength="150"/>
                              <label>E-mail</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                    </form>
                    <form className="endereco">
                      <Title level={3}>Endereço</Title>
                      <Row className="btnCopyEndereco">
                      <React.Fragment>
                      <Col md={24} className="btnCopyEndereco">
                            <Form.Item className="label-float">
                            <Button type="primary" className="" block size="large" onClick={this.copiaEnderecoProprietario} >Copiar do Proprietário</Button>
                           </Form.Item>
                      </Col>
                      </React.Fragment>
                      </Row>
                      <Row>
                      <React.Fragment>
                          <Col md={8}>
                            <Form.Item label="CEP" className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" disabled={this.state.disabled} placeholder="CEP" size="large" value={this.state.cep} onChange={this.setCep} maxLength="10" />
                              <label>CEP</label>
                              <Button type="primary" size="large" block onClick={this.buscaCep} icon={<SearchOutlined />} style={{ float: 'right', marginTop: '-40px' }} />
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Logradouro" className="label-float">
                              <Input placeholder="Logradouro" size="large" value={this.state.rua} onChange={this.setRua} maxLength="100" />
                              <label>Logradouro</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={4}>
                            <Form.Item label="Número" className="label-float">
                              <Input placeholder="Número" size="large" value={this.state.numero} onChange={this.setNumero} maxLength="10"/>
                              <label>Número</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Complemento" className="label-float">
                              <Input placeholder="Complemento" size="large" value={this.state.complemento} onChange={this.setComplemento} maxLength="50"/>
                              <label>Complemento</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Bairro" className="label-float">
                              <Input placeholder="Bairro" size="large" value={this.state.bairro} onChange={this.setBairro} maxLength="50"/>
                              <label>Bairro</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <React.Fragment>
                          <Col md={10}>
                            <Form.Item label="Pais" className="label-float">
                              <Select placeholder="Selecione o Pais" size="large" value={this.state.pais} onChange={this.setPais} defaultValue={this.state.NMPAIS} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {paises.map((pais) => (<Select.Option key={pais.cdPais} value={pais.cdPais} >{pais.nmPais}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">País</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={4}>
                            <Form.Item label="UF" className="label-float">
                              <Select disabled={this.state.disabled} placeholder="Selecione o Estado" size="large" value={this.state.ufEnd} onChange={this.setUfEnd} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {ufs.map((uf) => (<Select.Option key={uf.cdPais} value={uf.sgUnidadeFederal} >{uf.sgUnidadeFederal}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">UF</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                        <React.Fragment>
                          <Col md={10}>
                            <Form.Item label="Município" className="label-float">
                              <Select disabled={this.state.disabled} placeholder="Selecione o Município" size="large" value={this.state.deMunicipioEnd} onChange={this.setMunicipioEnd} showSearch filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {municipios.map((municipio) => (<Select.Option key={municipio.cdMunicipio} value={municipio.cdMunicipio}>{municipio.nmMunicipio}</Select.Option>))}
                              </Select>
                              <label className="selectlabel">Município</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        
                        <React.Fragment>
                          <Col md={12}>
                            <Form.Item label="Telefone" className="label-float">
                              <Input type="text" pattern="[0-9]*" inputmode="numeric" placeholder="Telefone" size="large" value={this.state.telefone} onChange={this.setTelefone} maxLength="14" />
                              <label>Telefone</label>
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      </Row>
                      <Row>
                        <Col md={24} id="botoes-navegacao">
                          <Form.Item>
                            <Button type="primary" size="large" block onClick={this.salvarDadosCondutor} 
                              disabled={!this.state.rg || !this.state.ufRg || !this.state.nome || !this.state.nacionalidade 
                              || !this.state.naturalidade || !this.state.flSexo || !this.state.dataNasc || !this.state.cdEstadoCivil 
                              || !this.state.cdMunicipioEnd || !this.state.rua || !this.state.email || !this.state.cep || !this.state.telefone 
                              || !this.state.pais || !this.state.ufEnd || !this.state.bairro}>
                              <Link to={`/declaracao/${this.state.uuid}`}>Proximo</Link>
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button size="large" block onClick={this.salvarDadosCondutor} ><Link to={`/proprietario/${this.state.uuid}`}>Anterior</Link></Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </form>
                  </Col>
                  <Col lg={6}>
                    <Steps direction="vertical" percent={48} current={2} style={{ width: 'auto' }}>
                      <Step title="Dados do Veículo" />
                      <Step title="Dados do Proprietário" />
                      <Step title="Dados do Condutor" />
                      <Step title="Declaração do Condutor" />
                      <Step title="Dados da Habilitação" />
                      <Step title="Fotos das Avarias" />
                    </Steps>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Condutor;