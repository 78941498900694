import { getParametro } from "../service/api";

export const getBaseDados = () => {
    return getParametro("prmBaseDados").then(function (response) {
        var vlPrmBaseDados = response.data.vlParametro;
        var deBase = ""
          if(vlPrmBaseDados === "DES"){
            deBase = "DESENVOLVIMENTO";
          } else if (vlPrmBaseDados === "HML"){
            deBase= "HOMOLOGAÇÃO";
           }
           return deBase;
      });
}
